import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/modules/authentication/auth.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RefreshTokenGuard  {
  constructor(private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.refreshTokenAsync()!;
  }
}
